import Skeleton from '@material-ui/lab/Skeleton';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { removeVouchers, selectVouchers } from "../../actions/miniCartActions";
import Toast from '../../helpers/Toast';
import useCurrencies from '../../hooks/useCurrencies';
import { IsValidNumber } from "../../Utils/UsefulFunctions";
import { HasError } from "../../Utils/UtilsCheckout";
import IHTPButton from '../Common/IHTPButton';
import IHTPInput from '../Common/IHTPInput';
import IHTPSpacer from '../Common/IHTPSpacer';
import Label, { Type } from '../Common/Label';
import "./Checkout.css";
import useCheckoutDisabledInputs from './useCheckoutDisabledInputs';

export default function Vales() {

	const currentVouchersCheckout = useSelector((state: any) => state.checkoutData.vouchers);
	let valesPermitidos = useSelector((state: any) => state.checkoutData.valesPermitidos);
	const currencies = useCurrencies();
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const errorCode = useSelector((state: any) => state.errorCode);

	const [vouchers, setVouchers] = useState<string>("");
	const [hasError, setHasError] = useState<boolean>(false);
	const [loadingVoucher, setLoadingVoucher] = useState<boolean>(false);

	const { disabledFields } = useCheckoutDisabledInputs();

	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		let tmpHasError = HasError(errorCode, ["Vales_Codigos", "Creditos_Vales", "Vales"]);
		if (hasError !== tmpHasError) {
			setHasError(tmpHasError);
		}
		return () => { }
	}, [errorCode])

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("vales");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError])

	const handleChange = (text) => {

		if (IsValidNumber(text) === false) {
			setVouchers("");
			return;
		}

		const finalValue = Number(text);
		const maxAllowedVouchers = parseFloat(valesPermitidos);

		// Somente consegue adicionar um valor até perfazer o valor de vales permitidos
		if (finalValue <= maxAllowedVouchers) {
			setVouchers(text)
		}
	}

	const handleFormSubmit = async () => {
		var tmpVoucher = vouchers;
		if (!vouchers || vouchers === "0") {
			tmpVoucher = String(valesPermitidos ?? 0);
		}

		var totalVouchers = 0;

		//Substituimos , para . 
		tmpVoucher = tmpVoucher.replace(/,/g, ".");

		//Se não for um número ou for um valor menor que 0 mostramos erro
		var vouchersInNumber = Number(tmpVoucher);
		if (isNaN(vouchersInNumber) || vouchersInNumber < 0) {
			Toast.Show("error", intl.formatMessage({ id: "vales.erro.valorInvalido" }));
			return;
		}

		//Se já tiver lá vouchers
		if (typeof currentVouchersCheckout !== "undefined" && currentVouchersCheckout != null && Number.isNaN(Number(currentVouchersCheckout)) == false) {
			totalVouchers = parseFloat(Number(currentVouchersCheckout) + "") + parseFloat(tmpVoucher);

			//Se ainda não tivermos vouchers
		} else {
			totalVouchers = parseFloat(tmpVoucher);
		}

		setLoadingVoucher(true);
		var data: any = await dispatch(selectVouchers(totalVouchers));
		setLoadingVoucher(false);

		if (typeof data.success !== "undefined") {
			setVouchers("");

			Toast.Show("success", intl.formatMessage({ id: data.success }));
			return;
		}

		if (typeof data.error !== "undefined") {
			setVouchers("");
			await dispatch(removeVouchers());
			Toast.Show("error", data.error);

		} else {
			Toast.Show("error", intl.formatMessage({ id: "vales.erro.semVale" }));
		}
	}

	var loadingPrices = loadingCheckoutPrices;
  /* valesPermitidos = 2000; */
	return (
		<div>
			<div
				className={hasError === true ? "checkoutBox errorBorder"
					:/* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
					Math.round(valesPermitidos * 100) / 100 === 0
						? "checkoutBox disabledCreditsBox"
						: "checkoutBox "
				}
				id="vales">
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="checkoutBoxTitle">
						<FormattedMessage id="vales.titulo" />
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>

						<IHTPSpacer verticalSpace='10px' />

						<IHTPInput style={{ textAlign: "center" }} hideLabel={true}
							placeholder={intl.formatMessage({ id: "vales.inserirvalor" })}
							type="number" min={0}
							onChange={(t) => handleChange(t)} controlValue={true} value={vouchers}
							disabled={disabledFields} />

						<IHTPSpacer verticalSpace='10px' />

						<div className='discountsText'>
							<span className='vouchersAvailableContainer'>
								<span><FormattedMessage id="vales.utilizados" /></span>
								<span style={{ fontWeight: "600", color: "rgb(202, 0, 106)" }}>
									{loadingPrices === true ? (
										<Skeleton variant="text" width={50} height={24} />
									) : (
										<Label
											text={(currentVouchersCheckout ?? 0).toFixed(2) ?? 0}
											labelType={{
												type: Type.currency,
												currency: currencies.selectedDescription
											}} />
									)}
								</span>
							</span>

							<IHTPSpacer verticalSpace='10px' />

							<span className='vouchersAvailableContainer'>
								<span><FormattedMessage id="vales.consegueutilizar" /></span>
								<span style={{ fontWeight: "600", color: "rgb(202, 0, 106)" }}>
									{loadingPrices === true ? (
										<Skeleton variant="text" width={50} height={24} />
									) : (
										<Label
											text={(valesPermitidos ?? 0).toFixed(2)}
											labelType={{
												type: Type.currency,
												currency: currencies.selectedDescription
											}} />
									)}
								</span>
							</span>
						</div>

						<IHTPSpacer verticalSpace='10px' />

						<div className="buttonsValidarCodigos">
							<IHTPButton onClick={handleFormSubmit}
								text={intl.formatMessage({ id: "vales.validar" })}
								buttonStyle="secondary"
								loading={loadingVoucher}
								disabled={disabledFields}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}